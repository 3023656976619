import { Component, OnInit } from '@angular/core';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from '../services/account/account.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  private notifier: NotifierService;
  constructor(
    private accountService: AccountService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService,
    notifier: NotifierService
  ) {
    this.notifier = notifier;
  }

  loginForm: FormGroup;
  submitted: boolean = false;
  ngOnInit() {
    this.createLoginForm();
  }

  createLoginForm() {
    this.loginForm = this.fb.group({
      Email: ['', [Validators.required, Validators.email]],
      Password: ['', Validators.required],
    });
  }
  get f() {
    return this.loginForm.controls;
  }
  async login() {
    this.spinner.show();
    console.log(this.loginForm.controls);

    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    } else {
      this.accountService.login(this.loginForm.value).subscribe(
        (res: any) => {
          this.spinner.hide();
          console.log(res);
          if (res.valid == true) {
            this.accountService.saveToken(res.data.token);
            // this.notifier.notify('Success', 'Login Successfully');
            this.toastr.success('Login Successfully 😀', 'Success');
            // localStorage.setItem('token', res.data.token);
            if (res.data.Role == 'superadmin') {
              window.location.href = '/admin';
              // this.router.navigate(['/admin']);
            } else if (res.data.Role == 'company') {
              // this.router.navigate(['/company']);
              window.location.href = '/company';
            } else if (res.data.Role == 'contractor') {
              // this.router.navigate(['/contractor']);
              window.location.href = '/contractor';
            }
          }
        },
        (err) => {
          this.spinner.hide();
          if (err.status == 400) {
            this.toastr.error('Wrong Username and password 🙄', 'Error');
            console.log('Wrong Username and password 🙄');
          }
        }
      );
    }
  }
  reset() {
    this.loginForm.reset();
    this.submitted = false;
  }
}
