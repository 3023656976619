import { Component, OnInit } from '@angular/core';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from '../services/account/account.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contractor-register',
  templateUrl: './contractor-register.component.html',
  styleUrls: ['./contractor-register.component.css'],
})
export class ContractorRegisterComponent implements OnInit {
  constructor(
    private accountService: AccountService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService
  ) {}
  ngOnInit() {
    this.createContractorForm();
  }
  formsecond: boolean = false;
  enter() {
    this.formsecond = true;
  }
  contractorForm: FormGroup;
  submitted: boolean = false;
  createContractorForm() {
    this.contractorForm = this.fb.group({
      FirstName: ['', Validators.required],
      Surname: ['', Validators.required],
      PhoneNumber: ['', Validators.required],
      Email: ['', Validators.required],
      DOB: ['', Validators.required],
      HomeLocation: ['', Validators.required],
      Language: ['', Validators.required],
      OtherLanguage: ['', Validators.required],
      CompanyName: ['', Validators.required],
      UserName: ['', Validators.required],
      Password: ['', Validators.required],
      FieldOfExpertise: ['', Validators.required],
      Qualification: ['', Validators.required],
      DrivingLicence: ['', Validators.required],
      ValidPassport: ['', Validators.required],
      Specialities: ['', Validators.required],
      LinkdInURL: ['', Validators.required],
      UploadCVURL: ['', Validators.required],
      CalenderLink: ['', Validators.required],
    });
  }
  get f() {
    return this.contractorForm.controls;
  }
  validateAllFormFields(formGroup: FormGroup) {
    //{1}
    Object.keys(this.contractorForm.controls).forEach((field) => {
      //{2}
      const control = formGroup.get(field); //{3}
      if (control instanceof FormControl) {
        //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        //{5}
        this.validateAllFormFields(control); //{6}
      }
    });
  }
  isFieldValid(field: string) {
    return (
      (!this.contractorForm.get(field).valid &&
        this.contractorForm.get(field).touched) ||
      (this.contractorForm.get(field).untouched && this.submitted)
    );
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field),
    };
  }
  onSubmit() {
    this.spinner.show();
    this.submitted = true;
    // stop here if form is invalid
    if (this.contractorForm.invalid) {
      return;
    } else {
      this.validateAllFormFields(this.contractorForm);
      this.accountService
        .registerContractor(this.contractorForm.value)
        .subscribe(
          (res: any) => {
            this.spinner.hide();
            console.log(res);
            if (res.success == true) {
              this.toastr.success('Register Successfully 😀', 'Success');
              this.router.navigate(['/login']);
            }
          },
          (err) => {
            this.spinner.hide();
            console.log(err);
            this.toastr.error('Something went wrong 🙄', 'Error');
          }
        );
    }
  }
  reset() {
    this.contractorForm.reset();
    this.submitted = false;
  }
}
