<div class="container-fluid" class="bg-blue">
  <div class="row justify-content-center mt-0">
    <div class="col-11 col-sm-9 col-md-7 col-lg-6 text-center p-0 mt-3 mb-2">
      <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
        <h2><strong>Contractor Registration</strong></h2>
        <p>Fill all form field to go to next step</p>
        <div class="row">
          <div class="col-md-12 mx-0">
            <form
              id="msform"
              (ngSubmit)="onSubmit()"
              [formGroup]="contractorForm"
            >
              <!-- progressbar -->
              <ul id="progressbar">
                <li class="active" id="account"><strong>Step 1</strong></li>
                <li id="personal"><strong>Step 2</strong></li>
              </ul>
              <!-- fieldsets -->
              <fieldset>
                <div class="form-card">
                  <div
                    class="form-group position-relative has-icon-left mb-4"
                    [ngClass]="displayFieldCss('FirstName')"
                  >
                    <input
                      type="text"
                      class="form-control form-control-xl"
                      placeholder="First Name"
                      formControlName="FirstName"
                    />
                    <div class="form-control-icon">
                      <i class="bi bi-person"></i>
                    </div>
                    <app-field-error-display
                      [displayError]="isFieldValid('FirstName')"
                      errorMsg="Please provide your firstname"
                    >
                    </app-field-error-display>
                  </div>
                  <div
                    class="form-group position-relative has-icon-left mb-4"
                    [ngClass]="displayFieldCss('Surname')"
                  >
                    <input
                      type="text"
                      class="form-control form-control-xl"
                      placeholder="Surname"
                      formControlName="Surname"
                    />
                    <div class="form-control-icon">
                      <i class="bi bi-person"></i>
                    </div>
                    <app-field-error-display
                      [displayError]="isFieldValid('Surname')"
                      errorMsg="Please provide your surname"
                    >
                    </app-field-error-display>
                  </div>
                  <div
                    class="form-group position-relative has-icon-left mb-4"
                    [ngClass]="displayFieldCss('PhoneNumber')"
                  >
                    <input
                      type="text"
                      class="form-control form-control-xl"
                      placeholder="Phone Number"
                      formControlName="PhoneNumber"
                    />
                    <div class="form-control-icon">
                      <i class="bi bi-telephone"></i>
                    </div>
                    <app-field-error-display
                      [displayError]="isFieldValid('PhoneNumber')"
                      errorMsg="Please provide your phone number"
                    >
                    </app-field-error-display>
                  </div>
                  <div
                    class="form-group position-relative has-icon-left mb-4"
                    [ngClass]="displayFieldCss('Email')"
                  >
                    <input
                      type="text"
                      class="form-control form-control-xl"
                      placeholder="Email"
                      formControlName="Email"
                    />
                    <div class="form-control-icon">
                      <i class="bi bi-envelope"></i>
                    </div>
                    <app-field-error-display
                      [displayError]="isFieldValid('Email')"
                      errorMsg="Please provide your email"
                    >
                    </app-field-error-display>
                  </div>
                  <div
                    class="form-group position-relative has-icon-left mb-4"
                    [ngClass]="displayFieldCss('DOB')"
                  >
                    <input
                      type="text"
                      id="date"
                      class="form-control form-control-xl"
                      onfocus="(this.type='date')"
                      id="date"
                      placeholder="Date of Birth"
                      value="Date of Birth"
                      formControlName="DOB"
                    />
                    <div class="form-control-icon">
                      <i class="bi bi-calendar3"></i>
                    </div>
                    <app-field-error-display
                      [displayError]="isFieldValid('DOB')"
                      errorMsg="Please provide your Date of Birth"
                    >
                    </app-field-error-display>
                  </div>
                  <div
                    class="form-group position-relative has-icon-left mb-4"
                    [ngClass]="displayFieldCss('HomeLocation')"
                  >
                    <input
                      type="text"
                      class="form-control form-control-xl"
                      placeholder="Home Location"
                      formControlName="HomeLocation"
                    />
                    <div class="form-control-icon">
                      <i class="bi bi-geo-alt-fill"></i>
                    </div>
                    <app-field-error-display
                      [displayError]="isFieldValid('HomeLocation')"
                      errorMsg="Please provide your Date of Birth"
                    >
                    </app-field-error-display>
                  </div>
                  <div
                    class="form-group position-relative has-icon-left mb-4"
                    [ngClass]="displayFieldCss('Language')"
                  >
                    <input
                      type="text"
                      class="form-control form-control-xl"
                      placeholder="Languages"
                      formControlName="Language"
                    />
                    <div class="form-control-icon">
                      <i class="bi bi-type"></i>
                    </div>
                    <app-field-error-display
                      [displayError]="isFieldValid('Language')"
                      errorMsg="Please provide your language"
                    >
                    </app-field-error-display>
                  </div>
                  <div
                    class="form-group position-relative has-icon-left mb-4"
                    [ngClass]="displayFieldCss('OtherLanguage')"
                  >
                    <input
                      type="text"
                      class="form-control form-control-xl"
                      placeholder="Other Languages"
                      formControlName="OtherLanguage"
                    />
                    <div class="form-control-icon">
                      <i class="bi bi-type"></i>
                    </div>
                    <app-field-error-display
                      [displayError]="isFieldValid('OtherLanguage')"
                      errorMsg="Please provide your Other language"
                    >
                    </app-field-error-display>
                  </div>
                  <div
                    class="form-group position-relative has-icon-left mb-4"
                    [ngClass]="displayFieldCss('CompanyName')"
                  >
                    <input
                      type="text"
                      class="form-control form-control-xl"
                      placeholder="Company Name"
                      formControlName="CompanyName"
                    />
                    <div class="form-control-icon">
                      <i class="bi bi-building"></i>
                    </div>
                    <app-field-error-display
                      [displayError]="isFieldValid('CompanyName')"
                      errorMsg="Please provide your Company Name"
                    >
                    </app-field-error-display>
                  </div>
                  <div
                    class="form-group position-relative has-icon-left mb-4"
                    [ngClass]="displayFieldCss('UserName')"
                  >
                    <input
                      type="text"
                      class="form-control form-control-xl"
                      placeholder="Create User Name"
                      formControlName="UserName"
                    />
                    <div class="form-control-icon">
                      <i class="bi bi-person"></i>
                    </div>
                    <app-field-error-display
                      [displayError]="isFieldValid('UserName')"
                      errorMsg="Please provide your username"
                    >
                    </app-field-error-display>
                  </div>
                  <div
                    class="form-group position-relative has-icon-left mb-4"
                    [ngClass]="displayFieldCss('Password')"
                  >
                    <input
                      type="password"
                      class="form-control form-control-xl"
                      placeholder="Create Password"
                      formControlName="Password"
                    />
                    <div class="form-control-icon">
                      <i class="bi bi-shield-lock"></i>
                    </div>
                    <app-field-error-display
                      [displayError]="isFieldValid('Password')"
                      errorMsg="Please provide your password"
                    >
                    </app-field-error-display>
                  </div>
                  <!-- <div class="form-group position-relative has-icon-left mb-4">
                    <input
                      type="password"
                      class="form-control form-control-xl"
                      placeholder="Confirm Password"
                    />
                    <div class="form-control-icon">
                      <i class="bi bi-shield-lock"></i>
                    </div>
                  </div> -->
                  <!-- <a
                      (click)="enter()"
                      class="btn btn-primary btn-block btn-lg shadow-lg mt-5"
                    >
                      Enter
                    </a> -->
                </div>

                <input
                  type="button"
                  name="next"
                  class="btn btn-primary btn-lg shadow-lg next "
                  value="Next Step"
                />
              </fieldset>
              <fieldset>
                <div class="form-card">
                  <div
                    class="form-group position-relative has-icon-left mb-4"
                    [ngClass]="displayFieldCss('FieldOfExpertise')"
                  >
                    <input
                      type="text"
                      class="form-control form-control-xl"
                      placeholder="Field of Expertise"
                      formControlName="FieldOfExpertise"
                    />
                    <div class="form-control-icon">
                      <i class="bi bi-lightbulb"></i>
                    </div>
                    <app-field-error-display
                      [displayError]="isFieldValid('FieldOfExpertise')"
                      errorMsg="Please provide your Field Of Expertise"
                    >
                    </app-field-error-display>
                  </div>
                  <div
                    class="form-group position-relative has-icon-left mb-4"
                    [ngClass]="displayFieldCss('Qualification')"
                  >
                    <input
                      type="text"
                      class="form-control form-control-xl"
                      placeholder="Qualifications"
                      formControlName="Qualification"
                    />
                    <div class="form-control-icon">
                      <i class="bi bi-stack"></i>
                    </div>
                    <app-field-error-display
                      [displayError]="isFieldValid('Qualification')"
                      errorMsg="Please provide your Qualification"
                    >
                    </app-field-error-display>
                  </div>
                  <div
                    class="form-group position-relative has-icon-left mb-4"
                    [ngClass]="displayFieldCss('DrivingLicence')"
                  >
                    <input
                      type="text"
                      class="form-control form-control-xl"
                      placeholder="Driving Licence"
                      formControlName="DrivingLicence"
                    />
                    <div class="form-control-icon">
                      <i class="bi bi-card-list"></i>
                    </div>
                    <app-field-error-display
                      [displayError]="isFieldValid('DrivingLicence')"
                      errorMsg="Please provide your Driving Licence"
                    >
                    </app-field-error-display>
                  </div>
                  <div
                    class="form-group position-relative has-icon-left mb-4"
                    [ngClass]="displayFieldCss('ValidPassport')"
                  >
                    <input
                      type="text"
                      class="form-control form-control-xl"
                      placeholder="Valid Passport"
                      formControlName="ValidPassport"
                    />
                    <div class="form-control-icon">
                      <i class="bi bi-credit-card-2-front"></i>
                    </div>
                    <app-field-error-display
                      [displayError]="isFieldValid('ValidPassport')"
                      errorMsg="Please provide your Valid Passport"
                    >
                    </app-field-error-display>
                  </div>
                  <div
                    class="form-group position-relative has-icon-left mb-4"
                    [ngClass]="displayFieldCss('Specialities')"
                  >
                    <input
                      type="text"
                      class="form-control form-control-xl"
                      placeholder="Specialties"
                      formControlName="Specialities"
                    />
                    <div class="form-control-icon">
                      <i class="bi bi-search"></i>
                    </div>
                    <app-field-error-display
                      [displayError]="isFieldValid('Specialities')"
                      errorMsg="Please provide your Specialities"
                    >
                    </app-field-error-display>
                  </div>
                  <div
                    class="form-group position-relative has-icon-left mb-4"
                    [ngClass]="displayFieldCss('LinkdInURL')"
                  >
                    <input
                      type="text"
                      class="form-control form-control-xl"
                      placeholder="LinkedIn URL"
                      formControlName="LinkdInURL"
                    />
                    <div class="form-control-icon">
                      <i class="bi bi-linkedin"></i>
                    </div>
                    <app-field-error-display
                      [displayError]="isFieldValid('LinkdInURL')"
                      errorMsg="Please provide your LinkdInURL"
                    >
                    </app-field-error-display>
                  </div>
                  <div
                    class="form-group position-relative has-icon-left mb-4"
                    [ngClass]="displayFieldCss('UploadCVURL')"
                  >
                    <input
                      type="text"
                      class="form-control form-control-xl"
                      placeholder="Up Load CV"
                      formControlName="UploadCVURL"
                    />
                    <div class="form-control-icon">
                      <i class="bi bi-upload"></i>
                    </div>
                    <app-field-error-display
                      [displayError]="isFieldValid('UploadCVURL')"
                      errorMsg="Please provide your UploadCVURL"
                    >
                    </app-field-error-display>
                  </div>
                  <div
                    class="form-group position-relative has-icon-left mb-4"
                    [ngClass]="displayFieldCss('CalenderLink')"
                  >
                    <input
                      type="text"
                      class="form-control form-control-xl"
                      placeholder="Calendar Link"
                      formControlName="CalenderLink"
                    />
                    <div class="form-control-icon">
                      <i class="bi bi-link"></i>
                    </div>
                    <app-field-error-display
                      [displayError]="isFieldValid('CalenderLink')"
                      errorMsg="Please provide your CalenderLink"
                    >
                    </app-field-error-display>
                  </div>

                  <!-- <button
                    class="btn btn-primary btn-block btn-lg shadow-lg mt-5"
                  >
                    Sign Up
                  </button> -->
                </div>
                <button
                  type="button"
                  name="previous"
                  class="previous action-button-previous btn btn-primary shadow-lg"
                  
                >
                  Previous
                </button>
                <button
                  [disabled]="!contractorForm.valid"
                  class="btn btn-primary btn-lg shadow-lg" value=""
                >
                  Signup
                </button>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
