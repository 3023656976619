<div id="auth">
  <div class="row bg-blue height-100">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-60 mb-60">
      <div class="card border login">
        <div class="card-body">
          <h1 class="auth-title">Log in.</h1>
          <p class="auth-subtitle mb-5">
            Log in with your data that you entered during registration.
          </p>

          <form (ngSubmit)="login()" [formGroup]="loginForm">
            <div class="form-group position-relative has-icon-left mb-4">
              <input
                type="text"
                class="form-control form-control-xl"
                placeholder="Email"
                formControlName="Email"
                [ngClass]="
                  f.Email?.touched
                    ? f.Email?.hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                    : ''
                "
              />

              <div class="form-control-icon">
                <i class="bi bi-person"></i>
              </div>
              <div *ngIf="submitted && f.Email.errors" class="invalid-feedback">
                <div *ngIf="f.Email.errors.required">Email is required</div>
              </div>
            </div>

            <div class="form-group position-relative has-icon-left mb-4">
              <input
                type="password"
                class="form-control form-control-xl"
                placeholder="Password"
                formControlName="Password"
                [ngClass]="
                  f.Password?.touched
                    ? f.Password?.hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                    : ''
                "
              />

              <div class="form-control-icon">
                <i class="bi bi-shield-lock"></i>
              </div>
              <div
                *ngIf="submitted && f.Password.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.Password.errors.required">
                  Password is required
                </div>
              </div>
            </div>

            <div class="form-check form-check-lg d-flex align-items-end">
              <input
                class="form-check-input me-2"
                type="checkbox"
                checked
                value=""
                id="flexCheckDefault"
              />
              <label
                class="form-check-label text-gray-600"
                for="flexCheckDefault"
              >
                Keep me logged in
              </label>
            </div>
            <div class="text-center">
              <!-- <a
                class="btn btn-success btn-lg shadow-lg mt-5 w-32"
                href="/contractor-register"
              >
                Contractor</a
              >&nbsp; -->
              <button
                [disabled]="!loginForm.valid"
                class="btn btn-primary btn-lg shadow-lg mt-5 w-32"
              >
                Log in</button
              >&nbsp;
              <!-- <button
                class="btn btn-danger btn-lg shadow-lg mt-5 w-32"
                routerLink="/company-register"
              >
                Company
              </button> -->
            </div>
          </form>
          <div class="text-center mt-5 text-lg fs-4">
            <p class="text-gray-600">
              Don't have an account?
              <a
                style="cursor: pointer"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalCenter"
                class="font-bold"
                >Sign up</a
              >.
            </p>
            <p>
              <a class="font-bold" routerLink="/forgot-password"
                >Forgot password?</a
              >.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="exampleModalCenter"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-dialog-centered modal-dialog-centered modal-dialog-scrollable"
    role="document"
  >
    <div class="modal-content signup-modal">
      <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel1">
          Signup with Contractor or Company
        </h5>
        <!-- <button
          type="button"
          class="close rounded-pill btn btn-danger"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i class="fas fa-window-close"></i>
        </button> -->
        <button
          type="button"
          class="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i class="fas fa-times fa-3x"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center mt-70">
          <a
            class="btn btn-success btn-lg shadow-lg w-32"
            href="/contractor-register"
          >
            Contractor</a
          >&nbsp; &nbsp;
          <a
            class="btn btn-danger btn-lg shadow-lg w-32"
            href="/company-register"
          >
            Company
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
