<div id="auth">
  <div class="row bg-blue">
    <div class="col-lg-12 col-12 mt-60 mb-60">
      <div class="card sing-up-c border">
        <div class="card-body">
          <h1 class="auth-title">Company Registration</h1>
          <p class="auth-subtitle mb-5">
            Input your data to register to our website.
          </p>

          <form (ngSubmit)="onSubmit()" [formGroup]="companyForm">
            <div class="form-group position-relative has-icon-left mb-4">
              <input
                type="text"
                class="form-control form-control-xl"
                placeholder="Company Name"
                formControlName="CompanyName"
                [ngClass]="
                  f.CompanyName?.touched
                    ? f.CompanyName?.hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                    : ''
                "
              />
              <div class="form-control-icon">
                <i class="bi bi-building"></i>
              </div>
              <div
                *ngIf="submitted && f.CompanyName.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.CompanyName.errors.required">
                  Company Name is required
                </div>
              </div>
            </div>
            <div class="form-group position-relative has-icon-left mb-4">
              <input
                type="text"
                class="form-control form-control-xl"
                placeholder="Email"
                formControlName="Email"
                [ngClass]="
                  f.Email?.touched
                    ? f.Email?.hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                    : ''
                "
              />
              <div class="form-control-icon">
                <i class="bi bi-person"></i>
              </div>
              <div *ngIf="submitted && f.Email.errors" class="invalid-feedback">
                <div *ngIf="f.Email.errors.required">Email is required</div>
              </div>
            </div>
            <div class="form-group position-relative has-icon-left mb-4">
              <input
                type="text"
                class="form-control form-control-xl"
                placeholder="Office Location"
                formControlName="OfficeLocation"
                [ngClass]="
                  f.OfficeLocation?.touched
                    ? f.OfficeLocation?.hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                    : ''
                "
              />
              <div class="form-control-icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <div
                *ngIf="submitted && f.OfficeLocation.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.OfficeLocation.errors.required">
                  Office Location is required
                </div>
              </div>
            </div>
            <div class="form-group position-relative has-icon-left mb-4">
              <input
                type="text"
                class="form-control form-control-xl"
                placeholder="Field of Expertise"
                formControlName="FieldOfExpertise"
                [ngClass]="
                  f.FieldOfExpertise?.touched
                    ? f.FieldOfExpertise?.hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                    : ''
                "
              />
              <div class="form-control-icon">
                <i class="bi bi-lightbulb"></i>
              </div>
              <div
                *ngIf="submitted && f.FieldOfExpertise.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.FieldOfExpertise.errors.required">
                  Field Of Expertise is required
                </div>
              </div>
            </div>
            <div class="form-group position-relative has-icon-left mb-4">
              <input
                type="text"
                class="form-control form-control-xl"
                placeholder="Area of Operation"
                formControlName="AreaOfOperation"
                [ngClass]="
                  f.AreaOfOperation?.touched
                    ? f.AreaOfOperation?.hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                    : ''
                "
              />
              <div class="form-control-icon">
                <i class="bi bi-gear-wide-connected"></i>
              </div>
              <div
                *ngIf="submitted && f.AreaOfOperation.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.AreaOfOperation.errors.required">
                  Area Of Operation is required
                </div>
              </div>
            </div>
            <div class="form-group position-relative has-icon-left mb-4">
              <input
                type="text"
                class="form-control form-control-xl"
                placeholder="Quantity"
                formControlName="QualificationOfContractors"
                [ngClass]="
                  f.QualificationOfContractors?.touched
                    ? f.QualificationOfContractors?.hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                    : ''
                "
              />
              <div class="form-control-icon">
                <i class="bi bi-basket3-fill"></i>
              </div>
              <div
                *ngIf="submitted && f.QualificationOfContractors.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.QualificationOfContractors.errors.required">
                  Qualification Of Contractors Description is required
                </div>
              </div>
            </div>
            <div class="form-group position-relative has-icon-left mb-4">
              <input
                type="text"
                class="form-control form-control-xl"
                placeholder="Company Description"
                formControlName="CompanyDescription"
                [ngClass]="
                  f.CompanyDescription?.touched
                    ? f.CompanyDescription?.hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                    : ''
                "
              />
              <div class="form-control-icon">
                <i class="bi bi-chat-right-text"></i>
              </div>
              <div
                *ngIf="submitted && f.CompanyDescription.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.CompanyDescription.errors.required">
                  Company Description is required
                </div>
              </div>
            </div>

            <div class="form-group position-relative has-icon-left mb-4">
              <input
                type="text"
                class="form-control form-control-xl"
                placeholder="Website URL"
                formControlName="WebsiteURL"
                [ngClass]="
                  f.WebsiteURL?.touched
                    ? f.WebsiteURL?.hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                    : ''
                "
              />
              <div class="form-control-icon">
                <i class="bi bi-globe"></i>
              </div>
              <div
                *ngIf="submitted && f.WebsiteURL.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.WebsiteURL.errors.required">
                  WebsiteURL is required
                </div>
              </div>
            </div>

            <button
              [disabled]="!companyForm.valid"
              class="btn btn-primary btn-block btn-lg shadow-lg mt-5"
            >
              Signup
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
