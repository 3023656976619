import { Component, OnInit } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/angular'; // useful for typechecking
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  constructor(private http: HttpClient, private router: Router) {}
  title = 'BhiveWeb';
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    dateClick: this.handleDateClick.bind(this), // bind is important!
  };
  handleDateClick(arg) {
    console.log(arg);
  }
  ngOnInit(): void {
    let data = JSON.parse(localStorage.getItem('auth_meta'));
    if (data) {
      if (data.data.Role == 'superadmin') {
        this.router.navigate(['/admin']);
      }
      if (data.data.Role == 'company') {
        this.router.navigate(['/company']);
      }
      if (data.data.Role == 'contractor') {
        this.router.navigate(['/contractor']);
      } else {
      }
    }
  }
}
