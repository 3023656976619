import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';

const jwt = new JwtHelperService();

class DecodedToken {
  exp: number;
  username: string;
}
@Injectable({
  providedIn: 'root',
})
export class AccountService {
  public decodedToken: any;
  t = localStorage.getItem('auth_token');
  token = 'Bearer ' + this.t;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.token,
    }),
  };

  constructor(private http: HttpClient) {
    this.decodedToken =
      JSON.parse(localStorage.getItem('auth_meta')) || new DecodedToken();
  }
  login(data: any) {
    return this.http.post(environment.apiUrl + 'auth/login', data);
  }
  registerContractor(data: any) {
    return this.http.post(environment.apiUrl + 'contractor/registration', data);
  }
  registerCompany(data: any) {
    return this.http.post(environment.apiUrl + 'company/registration', data);
  }
  forgotPassword(email: any) {
    return this.http.get(
      environment.apiUrl + `auth/forgetpasswordemail?email=${email}`
    );
  }
  changePassword(data: any) {
    return this.http.post(
      environment.apiUrl + `auth/changepasswordfromforgetpassword`,
      data
    );
  }

  getUser(id: any) {
    return this.http.get(environment.apiUrl + `auth/${id}`);
  }
  updateUser(data: any, id: any) {
    return this.http.patch(
      environment.apiUrl + `auth/${id}`,
      data,
      this.httpOptions
    );
  }

  saveToken(token: any): any {
    this.decodedToken = jwt.decodeToken(token);
    localStorage.setItem('auth_token', token);
    localStorage.setItem('auth_meta', JSON.stringify(this.decodedToken));
    return token;
  }
  logout() {
    localStorage.clear();

    this.decodedToken = new DecodedToken();
  }
  isAuthenticated(): any {
    return !moment().isBefore(moment.unix(this.decodedToken.iat));
  }
  // getUsername(): string {
  //   return this.decodedToken.data.Name;
  // }
  isSuperadmin() {
    let data = JSON.parse(localStorage.getItem('auth_meta'));
    if (data.data.Role == 'superadmin') {
      return true;
    }
  }
  isContractor() {
    let data = JSON.parse(localStorage.getItem('auth_meta'));
    if (data.data.Role == 'contractor') {
      return true;
    }
  }
  isCompany() {
    let data = JSON.parse(localStorage.getItem('auth_meta'));
    if (data.data.Role == 'company') {
      return true;
    }
  }
}
