import { Component, OnInit } from '@angular/core';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from '../services/account/account.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-company-register',
  templateUrl: './company-register.component.html',
  styleUrls: ['./company-register.component.css'],
})
export class CompanyRegisterComponent implements OnInit {
  constructor(
    private accountService: AccountService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  companyForm: FormGroup;
  submitted: boolean = false;
  ngOnInit() {
    this.createCompanyForm();
  }
  createCompanyForm() {
    this.companyForm = this.fb.group({
      CompanyName: ['', Validators.required],
      Email: ['', Validators.required],
      OfficeLocation: ['', Validators.required],
      FieldOfExpertise: ['', Validators.required],
      AreaOfOperation: ['', Validators.required],
      QualificationOfContractors: ['', Validators.required],
      CompanyDescription: ['', Validators.required],
      WebsiteURL: ['', Validators.required],
    });
  }
  get f() {
    return this.companyForm.controls;
  }
  onSubmit() {
    this.spinner.show();
    this.submitted = true;
    // stop here if form is invalid
    if (this.companyForm.invalid) {
      return;
    } else {
      this.accountService.registerCompany(this.companyForm.value).subscribe(
        (res: any) => {
          this.spinner.hide();
          console.log(res);
          if (res.success == true) {
            this.toastr.success('Register Successfully 😀', 'Success');
            this.router.navigate(['/login']);
          }
        },
        (err) => {
          console.log(err);
          this.toastr.error('Something went wrong 🙄', 'Error');
        }
      );
    }
  }
  reset() {
    this.companyForm.reset();
    this.submitted = false;
  }
}
